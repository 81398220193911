import React from "react";
import "./Services.css";

const Services = () => {
  return (
    <div className="Services">
      <div className="Services-content">
        <h1>Treatments Available</h1>
        <div className="Services-desc-container">
          <div className="Services-desc">
            <h2>Therapeutic Swedish Massage</h2>
            <p>
              A gentle, relaxing massage that uses long, flowing strokes to
              improve circulation, reduce muscle tension, and promote
              relaxation. Myofascial release and/or partial lymphatic drainage
              applied when fit.
            </p>
          </div>
          <div className="Services-desc">
            <h2>Lymphatic Drainage</h2>
            <p>
              LDT is a gentle technique that works through the body's
              interstitial and lymphatic system to activate the body's liquid
              circulation and stimulate the functioning of the immune and
              parasympathetic nervous systems.
            </p>
          </div>
          <div className="Services-desc">
            <h2>Neuromuscular Therapy</h2>
            <p>
              Focused on one or two muscle groups per session. Neuromuscular
              therapy incorporates a combination of myofascial release, trigger
              point therapy, muscle energy techniques.
            </p>
          </div>
        </div>

        <div className="Services-prices">
          <h1>Prices</h1>
          <div className="Services-prices-container">
            <div className="Services-prices-item">
              <h3>$100/60 Minutes</h3>
            </div>
            <div className="Services-prices-item">
              <h3>$150/90 Minutes</h3>
            </div>
            <div className="Services-prices-item">
              <h3>$200/120 Minutes</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
