import React from "react";
import "./Incentives.css";

const Incentives = () => {
  return (
    <div className="Incentives">
      <div className="Incentives-content">
        <h1>Incentives</h1>
        <p style={{ marginTop: "40px" }}>
          Check out our facebook and google page for seasonal promotions!
        </p>
        <p style={{ marginTop: "20px" }}>
          Seniors (65+) and College Students get 20% off of regular price!
        </p>
        <div className="Incentives-desc-container">
          <div className="Incentives-desc">
            <h2>Celebrating You</h2>
            <h3>BIRTHDAY DISCOUNT</h3>
            <p>
              Become a client. Each year, about one week before your birthday,
              you will receive an email that has a promo code, and instructions
              on when and how to use the discount. The offer is valid for 30
              days after your birthday.
            </p>
            <p>
              New client, for the 30 day period before your birthday, you’ll
              receive 20% off the regular price of the session you choose.
            </p>
            <p> I look forward to celebrating you!</p>
          </div>
          <div className="Incentives-desc">
            <h2>Referral Program</h2>
            <h3>SAVE MONEY FOR BOTH YOU AND YOUR FRIENDS</h3>
            <p>
              It's simple! If you are a current client, refer someone. When they
              come in for their first appointment and tell me that you referred
              them to me, you will receive 20% off of your next appointment!
              Also, the person that you referred will receive 20% off of their
              first visit with me!
            </p>
            <p>
              Each time you refer a new client and they come in for their first
              appointment, you will receive 20% off on your next appointment.
            </p>
            <p>Thank you for supporting me!</p>
          </div>
        </div>
        <p style={{ marginTop: "60px" }}>
          *Discounts cannot be combined except for the Birthday Celebration
        </p>
      </div>
    </div>
  );
};

export default Incentives;
